.widgets-panel {
    $self : &;
    height       : 100%;
    margin-left  : -15px;
    margin-right : -15px;

    &__nav {
        @include box-shadow($dropdown-shadow);

        #{$self}__nav-link {
            border        : none;
            border-radius : 0;
            color         : $body-color;

            &.active, &:hover {
                background : $body-color;
                border     : none;
                color      : $white;
            }
        }
    }

    &__nav-item {
        $self1 : &;
        margin-bottom : 0;

        #{$self1} ~ & {
            > #{$self}__nav-link {
                border-left : 1px solid $border-color;
            }
        }
    }
}

.widget {
    background : $secondary;
    color      : $white;
    padding    : $btn-padding-y-sm $btn-padding-y-sm;
}
