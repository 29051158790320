.page-header {
    background : $white;
    padding    : .75rem 2rem;
    top        : 60px;
}

.page-container {
    background : $white;
}

.preview-panel {
    background  : $light;
    padding-top : 37px;
}

.tools-panel {
    position : sticky;
    top      : 120px;
    height   : calc(100vh - 120px);
    overflow : hidden;
}
