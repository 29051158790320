@media screen and (max-width : 991px) {
    .navbar {
        padding : 0;

        &--header {
            position : absolute;
            left     : 0;
            right    : 0;
            z-index  : 1029;

            .nav-link {
                padding-left  : 1rem;
                padding-right : 1rem;

                .nav__icon {
                    margin-right : 1.25rem;
                }

                .nav__avatar {
                    margin-right : 1rem;
                }
            }
        }

        .brand__img {
            opacity : 0;
            @include ac-transition ($general-transition-time, cubic-bezier(0.685, 0.0473, 0.346, 1));

            &--visible {
                opacity : 1;
            }
        }

        .navbar-brand {
            font-size    : 16px;
            margin-right : 0;
        }

        .container-fluid {
            padding-right : 15px;
            padding-left  : 15px;
        }

        .navbar-nav {
            .nav-item:first-child {
                margin-top : 10px;
            }

            .nav-item:not(:last-child) {
                margin-bottom : 10px;
            }
        }

        .dropdown {
            $dropdown : &;
            margin-bottom : .5rem;

            &-menu {
                display : none;

                &.show {
                    display : block;
                }
            }

            &-toggle:after {
                margin-left : .5rem;
            }
        }

        .dropdown.show .dropdown-menu,
        .dropdown .dropdown-menu {
            background         : transparent;
            border             : 0;
            transition         : none;
            -webkit-box-shadow : none;
            width              : auto;
            margin             : 0 1rem;
            box-shadow         : none;
            position           : static;

            &:after, &:before {
                display : none;
            }

            .dropdown-item {
                padding : .5rem 2.8rem;
            }
        }

        .dropdown-menu .dropdown-item:focus,
        .dropdown-menu .dropdown-item:hover {
            color : $white;
        }

        .navbar-toggler {
            color : $body-color;
        }

        .navbar-toggler-bar {
            background    : $body-color;
            border-radius : 1px;
            display       : block;
            position      : relative;
            width         : 22px;
            height        : 1px;

            & + .navbar-toggler-bar {
                margin-top : 7px;
            }

            &.bar2 {
                width      : 17px;
                transition : width .2s linear;
            }
        }

        & .toggled .navbar-toggler-bar {
            width : 24px;

            & + .navbar-toggler-bar {
                margin-top : 5px;
            }
        }
    }

    .sidebar-open {
        position   : relative;
        overflow-x : hidden;

        .sidebar {
            @include transform-translate-x(0px);
        }

        .main {
            right : 0;
            @include transform-translate-x(260px);

            .content {
                -webkit-filter : blur(4px);
                filter         : blur(4px);
            }
        }
    }

    .bar1,
    .bar2,
    .bar3 {
        outline : 1px solid transparent;
    }
    .bar1 {
        top : 0;
        @include bar-animation(topbar-back);
    }
    .bar2 {
        opacity : 1;
    }
    .bar3 {
        bottom : 0;
        @include bar-animation(bottombar-back);
    }
    .toggled .bar1 {
        top : 6px;
        @include bar-animation(topbar-x);
    }
    .toggled .bar2 {
        opacity : 0;
    }
    .toggled .bar3 {
        bottom : 6px;
        @include bar-animation(bottombar-x);
    }

    @include topbar-x-rotation();
    @include topbar-back-rotation();
    @include bottombar-x-rotation();
    @include bottombar-back-rotation();

    @-webkit-keyframes fadeIn {
        0% {
            opacity : 0;
        }
        100% {
            opacity : 1;
        }
    }
    @-moz-keyframes fadeIn {
        0% {
            opacity : 0;
        }
        100% {
            opacity : 1;
        }
    }
    @keyframes fadeIn {
        0% {
            opacity : 0;
        }
        100% {
            opacity : 1;
        }
    }

    .content-overlay {
        background-color : rgba(0, 0, 0, 0.1);
        content          : "";
        opacity          : 0;
        overflow-x       : hidden;
        position         : fixed;
        bottom           : 0;
        top              : 60px;
        right            : 0;
        left             : 0;
        z-index          : 999;
        @include ac-transition ($general-transition-time, cubic-bezier(0.685, 0.0473, 0.346, 1));

        &.show {
            opacity : 1;
        }
    }

    .navbar-nav {
        .nav-link {
            i {
                opacity : .5;
            }
        }
    }

    .sidebar {
        position   : fixed;
        display    : block;
        top        : 0;
        height     : 100%;
        width      : 260px;
        right      : auto;
        left       : 0;
        z-index    : 1032;
        visibility : visible;
        overflow-y : visible;
        padding    : 0;
        @include ac-transition ($general-transition-time, cubic-bezier(0.685, 0.0473, 0.346, 1));

        @include transform-translate-x(-260px);
    }

    .main {
        width : 100%;
    }
}

@media screen and (min-width : 992px) {
    .navbar {
        &--header {
            .nav-item {
                display         : flex;
                align-items     : center;
                flex-direction  : column;
                justify-content : center;
            }

            .nav-item + .nav-item {
                margin-left : .5rem;
            }

            .nav__icon,
            .nav__avatar {
                margin-right : .5rem;
            }
        }
    }

    .navbar-collapse {
        background : none !important;
    }

    .navbar .navbar-toggle {
        display : none;
    }

    .dropdown-menu .dropdown-item {
        color : inherit;
    }

    .sidebar {
        .sidebar-wrapper {
            overflow-x : hidden;
        }
    }
}

@media screen and (max-width : 768px) {
    .main .content {
        padding-left  : 15px;
        padding-right : 15px;
        margin-top    : 60px; // to match header min-height (60px)
    }

    .card {
        .form-horizontal {
            .col-form-label.text-right {
                text-align : left !important;
            }
        }
    }

}

@media screen and (max-width : 576px) {
    .navbar[class*='navbar-toggleable-'] .container {
        margin-left  : 0;
        margin-right : 0;
    }
}
