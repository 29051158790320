a:focus, a.active, a.focus,
button:focus, button.active, button.focus,
.btn:focus, .btn.active, .btn.focus {
    outline    : none !important;
    box-shadow : none !important;
}

.btn {
    box-shadow : none;
}

.btn-info, .btn-info:hover, .btn-info:focus, .btn-info.active,
.btn-outline-info:not(:disabled):not(.disabled):hover,
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
    color : $white !important;
}
