.config-panel {
    background : $white;
    position   : absolute;
    top        : 0;
    right      : 0;
    bottom     : 0;
    left       : 0;
    transform  : translateX(100%);
    @include ac-transition($general-transition-time, $transition-ease);

    &--open {
        transform : translateX(0);
    }

    &__close {

    }
}

.property-group__label {
    background : $text-muted;
    color      : $white;
}
