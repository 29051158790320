.card {
    box-shadow : 0 4px 8px 0 hsla(0, 0%, 0%, 0.12);
    @include ac-transition($general-transition-time, $transition-ease);

    &:hover {
        box-shadow : 0 10px 15px 0 rgba(42, 51, 83, 0.08), 0 6px 8px rgba(0, 0, 0, 0.05);
    }

    &-title {
        color       : #4d4f5c;
        font-size   : 1.25rem;
        font-weight : $font-weight-bolder;
    }

    &-footer {
        background-color : lighten($light, 0.5);
        @include border-bottom-left-radius($card-border-radius);
        @include border-bottom-right-radius($card-border-radius);
    }

    &--with-list {
        &__title {
            margin-bottom : 0;
            padding       : 1.5rem;
        }
    }
}
