.navbar {
    $self : &;

    &--expanded {
        background : #313b43;
    }

    &--header {
        background : #313b43;
        min-height : 60px;
        padding    : .25rem 1rem;

        .nav-link {
            display     : flex;
            align-items : center;
            color       : $gray-500;

            @extend .animation-transition-general;

            &:hover {
                color : $white;
            }
        }

        .nav__icon {
            font-size : 1.3rem;
        }

        .nav__text {
            font-size      : 12px;
            text-transform : uppercase;
        }

        .navbar-brand {
            color          : $white;
            font-size      : $font-size-base;
            text-transform : uppercase;

            @include transform-translate-x(0px);
        }

        .dropdown-toggle:after {
            vertical-align : baseline;
        }
    }
}
