.form-group {
    position : relative;
}

.label {
    color : $gray-800;
}

.form-control {
    &:focus {
        box-shadow : inset 0 0 0 1px lighten($input-focus-border-color, 10);
    }

    .was-validated &:invalid,
    &.is-invalid {
        background-image : str_replace(url("data:image/svg+xml,%3Csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512'%3E%3Cpath fill='#{lighten($form-feedback-invalid-color, 10)}' stroke='white' stroke-width='48' d='M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z'%3E%3C/path%3E%3C/svg%3E"), "#", "%23");
        background-size  : 5px;

        &:focus {
            box-shadow : inset 0 0 0 1px lighten($form-feedback-invalid-color, 20);
        }
    }

    .was-validated &:valid,
    &.is-valid {
        &:focus {
            box-shadow : inset 0 0 0 1px lighten($form-feedback-valid-color, 20);
        }
    }
}

.invalid-feedback,
.valid-feedback {
    &.feedback--floating {
        display       : block;
        margin-top    : -1rem;
        margin-bottom : 1.5rem;
    }
}

.invalid-tooltip {
    background : transparent;
    color      : $form-feedback-invalid-color;
    margin     : 0;
    padding    : 0;
}

.form {
    &__actions {
        background                 : $light;
        border-bottom-left-radius  : $card-border-radius;
        border-bottom-right-radius : $card-border-radius;
        padding                    : 1.25rem;

        &--in-card {
            margin : 0 -20px -20px;
        }
    }
}

.strength-meter {
    position      : relative;
    height        : 3px;
    background    : #dddddd;
    margin        : 7px 0;
    border-radius : 2px;

    // Dynamically create the gap effect
    &:before,
    &:after {
        content      : '';
        height       : inherit;
        background   : transparent;
        display      : block;
        border-color : #ffffff;
        border-style : solid;
        border-width : 0 $strength-gap 0;
        position     : absolute;
        width        : calc(20% + #{$strength-gap});
        z-index      : 10;
    }

    // Dynamically create the gap effect
    &:before {
        left : calc(20% - #{($strength-gap / 2)});
    }

    // Dynamically create the gap effect
    &:after {
        right : calc(20% - #{($strength-gap / 2)});
    }
}

.strength-meter-fill {
    background    : transparent;
    height        : inherit;
    position      : absolute;
    width         : 0;
    border-radius : inherit;
    transition    : width 0.5s ease-in-out, background 0.25s;

    // Dynamically generate strength meter color styles
    @for $i from 1 through 5 {
        &[data-strength='#{$i - 1}'] {
            width      : (20% * $i);
            background : nth($strength-colors, $i);
        }
    }
}
