.wrapper--login {
    background : #313b43;
}

.form {
    $self : &;

    &--login {
        width : 300px;

        .btn#{$self}__submit {
            background   : #414e59;
            border-color : #414e59;
            color        : $white;
            padding      : 15px 0;
            @include border-top-radius(0);
            @include border-bottom-radius(8px);

            &:hover {
                background   : #3b4853;
                border-color : #3b4853;
                color        : $white;
            }
        }
    }
}

@media (min-width : 728px) {
    .form.form--login {
        width : 400px;
    }
}
