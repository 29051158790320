// Mixin for generating new styles
@mixin ac-transition($time, $type) {
    @each $pre in -webkit-, -moz-, -ms-, -o- {
        #{$pre + transition}: all $time $type;
    }
    transition : all $time $type;
}

@mixin rotate-180() {
    filter            : progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform : rotate(180deg);
    -ms-transform     : rotate(180deg);
    transform         : rotate(180deg);
}

@mixin bar-animation($type) {
    -webkit-animation           : $type 500ms linear 0s;
    -moz-animation              : $type 500ms linear 0s;
    animation                   : $type 500ms 0s;
    -webkit-animation-fill-mode : forwards;
    -moz-animation-fill-mode    : forwards;
    animation-fill-mode         : forwards;
}

@mixin transition-opacity($time, $type) {
    @each $pre in -webkit-, -moz-, -ms-, -o- {
        #{$pre + transition}: opacity $time $type;
    }
    transition : opacity $time $type;
}

@mixin transform-translate-y-dropdown($value) {
    @each $pre in -webkit-, -moz-, -ms-, -o- {
        #{$pre + transform}: translate3d(-20px, $value, 0) !important;
    }
    transform : translate3d(-20px, $value, 0) !important;
}

@mixin transform-translate-x($value) {
    @each $pre in -webkit-, -moz-, -ms-, -o- {
        #{$pre + transform}: translate3d($value, 0, 0) !important;
    }
    transform : translate3d($value, 0, 0);
}

@mixin transform-translate-y($value) {
    @each $pre in -webkit-, -moz-, -ms-, -o- {
        #{$pre + transform}: translate3d(0, $value, 0) !important;
    }
    transform : translate3d(0, $value, 0) !important;
}

@mixin transform-translate-y-fixed-plugin($value) {
    @each $pre in -webkit-, -moz-, -ms-, -o- {
        #{$pre + transform}: translate3d(0, $value, 0) !important;
    }
    transform : translate3d(0, $value, 0) !important;
}

@mixin linear-gradient($color, $bottomColor: #000000) {
    background : $color;
    background : -webkit-linear-gradient($color 0%, $bottomColor 80%);
    background : -o-linear-gradient($color 0%, $bottomColor 80%);
    background : -moz-linear-gradient($color 0%, $bottomColor 80%);
    background : linear-gradient($color 0%, $bottomColor 80%);
}

@mixin topbar-x-rotation() {
    @keyframes topbar-x {
        0% {
            top       : 0;
            transform : rotate(0deg);
        }
        45% {
            top       : 6px;
            transform : rotate(145deg);
        }
        75% {
            transform : rotate(130deg);
        }
        100% {
            transform : rotate(135deg);
        }
    }
    @-webkit-keyframes topbar-x {
        0% {
            top               : 0;
            -webkit-transform : rotate(0deg);
        }
        45% {
            top               : 6px;
            -webkit-transform : rotate(145deg);
        }
        75% {
            -webkit-transform : rotate(130deg);
        }
        100% {
            -webkit-transform : rotate(135deg);
        }
    }
    @-moz-keyframes topbar-x {
        0% {
            top            : 0;
            -moz-transform : rotate(0deg);
        }
        45% {
            top            : 6px;
            -moz-transform : rotate(145deg);
        }
        75% {
            -moz-transform : rotate(130deg);
        }
        100% {
            -moz-transform : rotate(135deg);
        }
    }
}


@mixin topbar-back-rotation() {
    @keyframes topbar-back {
        0% {
            top       : 6px;
            transform : rotate(135deg);
        }
        45% {
            transform : rotate(-10deg);
        }
        75% {
            transform : rotate(5deg);
        }
        100% {
            top       : 0;
            transform : rotate(0);
        }
    }

    @-webkit-keyframes topbar-back {
        0% {
            top               : 6px;
            -webkit-transform : rotate(135deg);
        }
        45% {
            -webkit-transform : rotate(-10deg);
        }
        75% {
            -webkit-transform : rotate(5deg);
        }
        100% {
            top               : 0;
            -webkit-transform : rotate(0);
        }
    }

    @-moz-keyframes topbar-back {
        0% {
            top            : 6px;
            -moz-transform : rotate(135deg);
        }
        45% {
            -moz-transform : rotate(-10deg);
        }
        75% {
            -moz-transform : rotate(5deg);
        }
        100% {
            top            : 0;
            -moz-transform : rotate(0);
        }
    }
}

@mixin bottombar-x-rotation() {
    @keyframes bottombar-x {
        0% {
            bottom    : 0;
            transform : rotate(0deg);
        }
        45% {
            bottom    : 6px;
            transform : rotate(-145deg);
        }
        75% {
            transform : rotate(-130deg);
        }
        100% {
            transform : rotate(-135deg);
        }
    }
    @-webkit-keyframes bottombar-x {
        0% {
            bottom            : 0;
            -webkit-transform : rotate(0deg);
        }
        45% {
            bottom            : 6px;
            -webkit-transform : rotate(-145deg);
        }
        75% {
            -webkit-transform : rotate(-130deg);
        }
        100% {
            -webkit-transform : rotate(-135deg);
        }
    }
    @-moz-keyframes bottombar-x {
        0% {
            bottom         : 0;
            -moz-transform : rotate(0deg);
        }
        45% {
            bottom         : 6px;
            -moz-transform : rotate(-145deg);
        }
        75% {
            -moz-transform : rotate(-130deg);
        }
        100% {
            -moz-transform : rotate(-135deg);
        }
    }
}

@mixin bottombar-back-rotation {
    @keyframes bottombar-back {
        0% {
            bottom    : 6px;
            transform : rotate(-135deg);
        }
        45% {
            transform : rotate(10deg);
        }
        75% {
            transform : rotate(-5deg);
        }
        100% {
            bottom    : 0;
            transform : rotate(0);
        }
    }
    @-webkit-keyframes bottombar-back {
        0% {
            bottom            : 6px;
            -webkit-transform : rotate(-135deg);
        }
        45% {
            -webkit-transform : rotate(10deg);
        }
        75% {
            -webkit-transform : rotate(-5deg);
        }
        100% {
            bottom            : 0;
            -webkit-transform : rotate(0);
        }
    }
    @-moz-keyframes bottombar-back {
        0% {
            bottom         : 6px;
            -moz-transform : rotate(-135deg);
        }
        45% {
            -moz-transform : rotate(10deg);
        }
        75% {
            -moz-transform : rotate(-5deg);
        }
        100% {
            bottom         : 0;
            -moz-transform : rotate(0);
        }
    }
}

@mixin badge-color($color) {
    border-color     : $color;
    background-color : $color;
}
