$blue : #3498db;
$cyan : #95d0f4;
$green : #1abc9c;
$orange : #e67e22;
$red : #e74c3c;
$light : #f1f3f5;
$dark : #2c3e50;
$secondary : #34495e;
$body-color : #6e7b8e;
$font-family-sans-serif : "Open Sans", sans-serif;
$font-size-base : .875rem;
$text-muted : #a6a7ad;
$spacer : 1.25rem;
$enable-shadows : true;

// Breadcrumb
$breadcrumb-bg : transparent;
$breadcrumb-padding-x : 0;
$breadcrumb-padding-y : 1rem;

// Cards
$card-border-color : transparent;
$card-border-radius : .5rem;
$card-border-width : 0;
$card-spacer-y : 1.25rem;

// list groups
$list-group-border-color : $light;

// Forms
$form-group-margin-bottom : 1.25rem;
$input-bg : #fefefe;
$input-border-color : #c4cdd5;
$input-box-shadow : inset 0 1px 0 0 rgba(63, 63, 68, 0.05);
$input-btn-line-height-sm : 1.3;
$input-focus-border-color : $cyan;

// Password strength
$strength-colors : (#e74c3c, orangered, #e67e22, yellowgreen, #1abc9c);
$strength-gap : 6px;

// Tables
$table-active-bg : rgba(0, 0, 0, 0.03);
$table-hover-bg : rgba(0, 0, 0, 0.03);
$table-head-bg : rgba(0, 0, 0, 0.03);

// App Constructor
$bg-primary : lighten($blue, 10%);
$bg-info : lighten($cyan, 10%);
$bg-success : lighten($green, 10%);
$bg-warning : lighten($orange, 10%);
$bg-danger : lighten($red, 10%);
$dropdown-shadow : 0 4px 8px 0 hsla(0, 0%, 0%, 0.06);
$general-transition-time : 300ms;
$fast-transition-time : 150ms;
$slow-transition-time : 500ms;
$transition-linear : linear;
$transition-bezier : cubic-bezier(0.34, 1.61, 0.7, 1);
$transition-ease : ease;
$sidebar-width : 260px;
$main-panel-background : $light;
$main-panel-width : calc(100% - 260px);
