.preview-element {
    position : relative;
    padding  : 20px;

    &__actions {
        background      : $info;
        color           : $white;
        display         : flex;
        align-items     : center;
        justify-content : space-evenly;
        width           : 100px;
        position        : absolute;
        top             : 0;
        left            : 50%;
        z-index         : 2;
        transform       : translateX(-50%) translateY(-50%);
    }

    &__selectable {
        position : absolute;
        top      : 0;
        right    : 0;
        bottom   : 0;
        left     : 0;
        opacity  : 0;
        z-index  : 1;
        @include ac-transition($fast-transition-time, $transition-ease);

        &:hover {
            opacity : 1;
        }

        &:after {
            content  : ' ';
            opacity  : 0;
            z-index  : 1;
            position : absolute;
            top      : 1px;
            left     : 1px;
            right    : 1px;
            bottom   : 1px;
            outline  : $info solid 2px;
            @include ac-transition($fast-transition-time, $transition-ease);
        }

        &:hover:after {
            opacity : 1;
        }
    }

    .preview-element {
        position : relative;
        z-index  : 2;
    }

    &--selected {
        opacity : 1;

        &:after {
            opacity : 1;
            outline : $primary solid 2px;
        }
    }
}
