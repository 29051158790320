.dropdown,
.dropup {
    .dropdown-menu {
        @include box-shadow($dropdown-shadow);
        display    : block;
        opacity    : 0;
        visibility : hidden;
        position   : absolute;
        transition : all 50ms cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.15s ease 0s, height 0s linear 0.20s;
        z-index    : -1; // Fix for disabling the click until visibility changes
    }

    &.show .dropdown-menu {
        opacity    : 1;
        visibility : visible;
        z-index    : 0;
    }
}

.dropup .dropdown-menu,
.dropdown-btn .dropdown-menu {
    @include transform-translate-y-dropdown(0px);
    top    : auto !important;
    bottom : 0 !important;
}

.dropup.show .dropdown-menu,
.dropdown-btn.show .dropdown-menu {
    opacity    : 1;
    visibility : visible;
}

.dropdown-menu {
    background-color : $white;
    border           : 0 none;
    border-radius    : $border-radius-lg;
    margin-top       : 10px;
    padding          : 0;

    .divider {
        background-color : $gray-400;
        margin           : 0;
    }

    .dropdown-item {
        color       : $body-color;
        font-size   : $font-size-base;
        padding     : 10px 15px;
        clear       : both;
        white-space : nowrap;
        width       : 100%;
        display     : block;
    }

    .dropdown-item:focus {
        outline : 0 !important;
    }

    .dropdown-item:first-child {
        border-bottom           : 0 none;
        border-top-left-radius  : $border-radius-lg;
        border-top-right-radius : $border-radius-lg;
    }

    .dropdown-item:last-child {
        border-bottom-left-radius  : $border-radius-lg;
        border-bottom-right-radius : $border-radius-lg;
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
        background-color : $gray-300;
        color            : $body-color;
        opacity          : 1;
        text-decoration  : none;
    }
}

.dropdown-divider {
    margin : 0 !important;
}

.dropdown-menu-right {
    right : -2px;
    left  : auto;
}

.navbar-nav .dropdown-menu:before,
.dropdown-btn .dropdown-menu:before,
.navbar-nav .dropdown-menu:after,
.dropdown-btn .dropdown-menu:after {
    border-left  : 8px solid rgba(0, 0, 0, 0);
    border-right : 8px solid rgba(0, 0, 0, 0);
    content      : "";
    display      : inline-block;
    position     : absolute;
    right        : 12px;
}

.navbar-nav .dropdown-menu:before,
.dropdown-btn .dropdown-menu:before {
    border-bottom : 8px solid $gray-400;
    top           : -9px;
}

.navbar-nav .dropdown-menu:after,
.dropdown-btn .dropdown-menu:after {
    border-bottom : 8px solid $white;
    top           : -8px;
}

@media screen and (min-width : 768px) {
    .navbar-nav .dropdown-item .dropdown-menu,
    .dropdown .dropdown-menu,
    .dropdown-btn .dropdown-menu {
        transform  : translate3d(0px, -40px, 0px);
        transition : all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.3s ease 0s, height 0s linear 0.35s;
    }
    .navbar-nav .dropdown-item.show .dropdown-menu,
    .dropdown.show .dropdown-menu,
    .dropdown-btn.show .dropdown-menu {
        transform  : translate3d(0px, 0px, 0px);
        visibility : visible !important;
    }

    .navbar-nav.navbar-right li .dropdown-menu:before,
    .navbar-nav.navbar-right li .dropdown-menu:after {
        left  : auto;
        right : 12px;
    }

    // no dragging the others navs in page
    body > .navbar-collapse.collapse {
        display : none !important;
    }
}

.dropup .dropdown-menu:before {
    border-top   : 11px solid #dcd9d1;
    border-left  : 11px solid transparent;
    border-right : 11px solid transparent;
    content      : "";
    display      : inline-block;
    position     : absolute;
    right        : 12px;
    bottom       : -12px;
}

.dropup .dropdown-menu:after {
    border-top   : 11px solid #ffffff;
    border-left  : 11px solid transparent;
    border-right : 11px solid transparent;
    content      : "";
    display      : inline-block;
    position     : absolute;
    right        : 12px;
    bottom       : -11px;
}

.dropup,
.dropdown {
    .dropdown-toggle:after {
        border      : none;
        content     : '\f078';
        font-family : 'FontAwesome', serif;
        font-size   : .575rem;
        margin-top  : 3px;
    }
}
