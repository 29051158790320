// Animations
a, .btn,
.nav-pills .nav-link,
.navbar,
.nav-tabs .nav-link,
.sidebar .nav .nav__link,
.tag,
.tag [data-role="remove"],
.animation-transition-general {
    @include ac-transition($slow-transition-time, $transition-ease);
}

//transition for dropdown caret
.dropdown-toggle:after,
.bootstrap-switch-label:before {
    @include ac-transition($fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded="true"]:after,
a[data-toggle="collapse"][aria-expanded="true"]:after,
.card-collapse .card a[data-toggle="collapse"][aria-expanded="true"] i,
.card-collapse .card a[data-toggle="collapse"].expanded i {
    @include rotate-180();
}
