.sidebar {
    $self : &;
    position : fixed;
    top      : 0;
    height   : 100%;
    bottom   : 0;
    width    : $sidebar-width;
    left     : 0;
    z-index  : 1030;
    @include transition (0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    .sidebar-wrapper {
        position       : relative;
        height         : calc(100vh - 60px);
        overflow       : auto;
        width          : 260px;
        z-index        : 4;
        padding-bottom : 5px;
    }

    .navbar-minimize {
        position : absolute;
        right    : 20px;
        top      : 2px;
        opacity  : 1;

        @extend .animation-transition-general;
    }

    .sidebar-background {
        position            : absolute;
        z-index             : 1;
        height              : 100%;
        width               : 100%;
        display             : block;
        top                 : 0;
        left                : 0;
        background-size     : cover;
        background-position : center center;

        &:after {
            position   : absolute;
            z-index    : 3;
            width      : 100%;
            height     : 100%;
            content    : "";
            display    : block;
            background : $white;
            opacity    : 1;
        }
    }

    .logo {
        background : #313b43;
        height     : 60px;
        position   : relative;
        padding    : .25rem .7rem;
        z-index    : 4;

        &__img,
        &__link {
            @extend .animation-transition-general;
        }

        &__img {
            opacity      : 1;
            float        : left;
            height       : 35px;
            text-align   : center;
            margin-left  : 10px;
            margin-right : 10px;
        }

        &__link {
            color          : $white;
            display        : flex;
            align-items    : center;
            opacity        : 1;
            text-transform : uppercase;
            @include transform-translate-x(0px);

            &:hover {
                text-decoration : none;
            }
        }
    }

    &:before,
    &:after {
        display  : block;
        content  : "";
        opacity  : 1;
        position : absolute;
        width    : 100%;
        height   : 100%;
        top      : 0;
        left     : 0;
    }

    &:after {
        @include linear-gradient(#3f4951, #262c34);
        z-index : 3;
    }
}

.nav--sidebar {
    $self : &;
    margin-top : 20px;
    display    : block;

    &__link {
        color          : $gray-500;
        padding        : 20px .7rem;
        text-transform : uppercase;
        cursor         : pointer;
        font-size      : 13px;
        line-height    : 30px;
        position       : relative;

        &:hover {
            color           : $white;
            text-decoration : none;
            transform       : scale(1.025);
        }
    }

    &__icon {
        font-size    : 20px;
        margin-left  : 10px;
        margin-right : 10px;
    }

    &__icon,
    &__text {
        opacity : .7;

        &:hover {
            opacity : 1;
        }
    }
}

.nav-link.nav-link--active {
    color   : $white !important;
    opacity : 1;
}
